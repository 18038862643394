import React from 'react'
import Layout from '../../components/layout'
import { Link } from 'gatsby'

const EonsOfJoy = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">is that a mountain range or are you just happy to see me?</span>
        </div>
      </div>
    </section>
    <section className="section song">
      <div className="container song is-fluid">
        <div className="columns">
          <div className="column is-half art eons_of_joy"></div>
          <div className="column is-one-quarter lyrics">
            <p>
              I’ve got the heat building up down deep <br/>
              gonna build a mountain or two <br/>
              gonna flex my mantle... make the ground shake <br/>
              gonna get tectonic on you.
            </p>

            <p>
              I used to think it was oh so simple <br/>
              perfection was my only aim <br/>
              Now you got me all torn up inside girl <br/>
              I don’t even know my name
            </p>

            <p>
              Yeah I hear you but it works both ways <br/>
              I was so happy to be free <br/>
              But now you got me swirling out of control <br/>
              Can’t tell who’s you and who is me
            </p>

            <p>
              My air was gentle and my breeze was light <br/>
              I felt at peace and free from harm <br/>
              now every time that you let off some steam  boy<br/>
              I become a raging storm
            </p>

            <p>
              Eons of anger<br/>
              confusion and pain<br/>
              The fire from below<br/>
              and the driving rain
            </p>

            <p>
              The power of creation <br/>
              the will to destroy <br/>
              Sudden understanding <br/>
              Eons of joy!
            </p>
          </div>

          <div className="column is-one-quarter lyrics">
            <p>
              What does it mean to be one with you? <br/>
              Do I lose all I thought I had? <br/>
              It seems so natural as we melt into each other <br/>
              this greater being is not so bad
            </p>

            <p>
              But these convulsions make me wonder <br/>
              these stones that smash me from above <br/>
              Is there a downside to this union? <br/>
              What kind of bargain is this love
            </p>

            <p>
              Yes I am thankful for foundation <br/>
              and feel a bliss to bond with you <br/>
              but I miss the simple joy of floating <br/>
              without a care, without a clue
            </p>

            <p>
              But I must have you know my dear <br/>
              my love for you exceeds all bounds <br/>
              the care we feel now for each other <br/>
              fuels the power that we’ve found
            </p>

            <p>
              Eons of anger<br/>
              confusion and pain<br/>
              The fire from below<br/>
              and the driving rain
            </p>

            <p>
              The power of creation <br/>
              the will to destroy <br/>
              Sudden understanding <br/>
              Eons of joy!
            </p>
          </div>
        </div>
      </div>
  </section>
  </Layout>
)

export default EonsOfJoy
